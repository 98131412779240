import React, { useState, useEffect, useRef } from "react";
import "./paymentgetway.css";
import "./rightside.css";
import axios from "axios";
import { FaAngleDown, FaAngleUp, FaCheck, FaLock } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";

import useNotification from "../../utils/useNotification";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading, Navbar, Navbar2 } from "../../common";
import {
  add_user_check_product,
  cheke_state,
  get_product_details,
  get_url_data,
} from "../../utils/Constant";
import images from "../../constants/images";
import { RiContactsFill, RiDiscountPercentLine } from "react-icons/ri";
import { MdOutlineCelebration, MdOutlinePayment } from "react-icons/md";
import { CiStar } from "react-icons/ci";

const Checkout2 = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const navigate = useNavigate();
  const location = useLocation();
  // const { id } = useParams();

  const name_data = location.state?.name_data;

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  const [isloading, setIsLoading] = useState(false);
  const [iscityloading, setIsCityLoading] = useState(false);
  const [getdata, setdata] = useState({});
  const [getcdata, setCdata] = useState({});
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [aptSuite, setAptSuite] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("United States");
  const [getstate, setState] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(getcdata?.phone || "");
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [shippingMethod, setShippingMethod] = useState("FREE SHIPPING");
  const [getcardid, setCardid] = useState(1);
  const [cardNumber, setCardNumber] = useState("");
  const [cardholdername, setcardholdername] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [exMonth, setExMonth] = useState("");
  const [exYear, setExYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [id, setId] = useState(null);
  const [price, setPrice] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [getorder_id, setorder_id] = useState(null);
  const [getskipModel, setSkipModel] = useState(false);

  const [discount_percent, setdiscount_percent] = useState(null);
  const [discount_amount, setdiscount_amount] = useState(null);

  // section 2 conditions
  const [getsection2, setSection2] = useState(false);
  const [sameAsShipping, setSameAsShipping] = useState(true); // Default to same address

  //billing address start
  const [country2, setCountry2] = useState("");
  const [firstName2, setFirstName2] = useState("");
  const [lastName2, setLastName2] = useState("");
  const [address2, setAddress2] = useState("");
  const [city2, setCity2] = useState("");
  const [getselectedState2, setSelectedState2] = useState({
    name: "",
    state_code: "",
  });
  const [getselectedStateCode2, setSelectedStateCode2] = useState({
    name: "",
    state_code: "",
  });
  const [postalCode2, setPostalCode2] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");

  // promocode
  const [discountCode, setDiscountCode] = useState("");
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [error, setError] = useState("");

  const handleApplyDiscount = async () => {
    setError(""); // Clear previous errors
    setApplyLoading(true); // Start loading
    try {
      // Replace this URL with your actual API endpoint
      const response = await axios.post(
        "https://lightgoldenrodyellow-okapi-586794.hostingersite.com/polomeds/api/check_coupons",
        {
          promo_codes: discountCode,
          amount: getdata?.product_price,
        }
      );

      // Check the API response status
      if (response.data.status == 1) {
        setApplyLoading(false);
        console.log("responce.data", response.data);
        setAppliedDiscount(response.data.data.code); // Store applied code
        setdiscount_percent(response.data.discount_percent);
        setdiscount_amount(response.data.discount_amount);
      } else {
        setApplyLoading(false);
        setAppliedDiscount(null);
        // setError("Invalid code");
        notifyError("Invalid code");
      }
    } catch (err) {
      setApplyLoading(false);

      setError("Something went wrong. Please try again.");
    }
  };
  // Remove discount handler
  const handleRemoveDiscount = () => {
    setDiscountCode(""); // Clear the discount code input
    setAppliedDiscount(null); // Reset applied discount state
    setdiscount_percent(0); // Reset discount percent
    setdiscount_amount(0); // Reset discount amount
  };

  // promocode end

  // Determine if a discount is applied
  const isDiscountApplied = discount_percent > 0 || discount_amount > 0;

  // Total text logic
  const totalText = isDiscountApplied ? "Latest price" : "Your total";

  const [applyLoading, setApplyLoading] = useState(false);

  // check terms start
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // check terms end

  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const { notifySuccess, notifyError } = useNotification();

  const product = location.state?.product;
  const contact_details_id = location.state?.contact_details_id;

  const fetchStates = async () => {
    try {
      const res = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          country: "United States",
        }
      );
      console.log("sfsdcs---->", res.data);
      setState(res.data.data.states);
    } catch (err) {
      console.error("Error fetching states:", err);
    }
  };

  const FetchProduct = async (id) => {
    setIsLoading(true);
    const params = {
      product_id: id,
    };

    await axios
      .post(get_product_details, params)
      .then((res) => {
        setIsLoading(false);
        console.log("res.data", res.data);
        if (res.data.status == 1) {
          setdata(res.data.product_data);
        } else {
          setdata({});
          navigate("/ErrorPage");
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log("err", err);
      });
  };

  const FetchCdata = async (cdata) => {
    setIsLoading(true);
    await axios
      .post(get_url_data, {
        data: cdata,
      })
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          setIsLoading(false);
          console.log("Response.data.decoded_data", Response.data.decoded_data);
          const data = Response.data.decoded_data;
          setEmail(data?.email);
          setFirstName(data?.fname);
          setLastName(data?.lname);
          setPhoneNumber(data?.phone);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("eeror------>", error);
      });
  };

  useEffect(() => {
    const currentUrl = window.location.href;

    // Parse the URL to extract query parameters
    const params = new URLSearchParams(new URL(currentUrl).search);

    // Get the 'uniqueId' parameter
    const id = params.get("uniqueId");
    const cdata = params.get("cdata");

    console.log("id", id);
    console.log("cdata", cdata);
    // Set the extracted 'uniqueId' to state
    setUniqueId(id);

    const urlParams = new URLSearchParams(window.location.search);
    const idValue = urlParams.get("id");

    // Set the extracted ID in the state
    if (idValue) {
      setId(idValue);
    }

    // set c data with api
    if (cdata) {
      FetchCdata(cdata);
    }

    fetchStates();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    console.log("location.state", location.state);
    console.log("idValue", idValue);
    FetchProduct(idValue);
  }, []);

  // useEffect(() => {
  //   // Get the query string from the URL
  //   const queryParams = new URLSearchParams(window.location.search);

  //   // Extract the 'id' parameter
  //   const idValue = queryParams.get("id");

  //   // Set the extracted ID in the state
  //   if (idValue) {
  //     setId(idValue);
  //   }

  //   console.log("id", id);
  // }, []);

  const [getallCitys, setAllCitys] = useState([]);
  const [getselectedState, setSelectedState] = useState({
    name: "",
    state_code: "",
  });
  const [getselectedStateCode, setSelectedStateCode] = useState({
    name: "",
    state_code: "",
  });

  // google map start
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  var initializeAutocomplete = () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps API not available.");
      setIsLoading(false);
      return;
    }

    const parseAddressComponents = (place) => {
      let stateName = "";
      let stateCode = "";
      let city = "";
      let postalCode = "";

      if (place && place.address_components) {
        // Loop through address components to extract desired fields
        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes("administrative_area_level_1")) {
            // State Name and Code
            stateName = component.long_name;
            stateCode = component.short_name; // Short name is usually the state code
          }
          if (types.includes("locality")) {
            // City
            city = component.long_name;
          }
          if (types.includes("postal_code")) {
            // Postal Code
            postalCode = component.long_name;
          }
        });
      }

      return { stateName, stateCode, city, postalCode };
    };

    const cleanAddress = (formattedAddress, componentsToRemove) => {
      // let cleanedAddress = formattedAddress;

      // componentsToRemove.forEach((component) => {
      //   if (component) {
      //     cleanedAddress = cleanedAddress
      //       .replace(component, "")
      //       .replace(/,\s*,/, ",")
      //       .trim();
      //   }
      // });

      // // Remove trailing commas and extra spaces
      // cleanedAddress = cleanedAddress
      //   .replace(/(^,)|(,$)/g, "")
      //   .replace(/\s{2,}/g, " ")
      //   .trim();

      // return cleanedAddress;

      // Split the address by commas and take only the first part
      const addressParts = formattedAddress.split(",");
      return addressParts[0].trim(); // Extracts only the first part (like "Tennessee")
    };

    // Initialize Autocomplete for the first input
    if (inputRef1.current) {
      const autocomplete1 = new window.google.maps.places.Autocomplete(
        inputRef1.current,
        {
          types: ["geocode"], // Restrict to address-type predictions
          componentRestrictions: { country: "us" },
        }
      );

      autocomplete1.addListener("place_changed", () => {
        const place = autocomplete1.getPlace();
        console.log("Place details (input 1):", place);

        if (place) {
          const { stateName, stateCode, city, postalCode } =
            parseAddressComponents(place);

          const cleanedAddress = cleanAddress(place.formatted_address, [
            city,
            stateName,
            postalCode,
          ]);

          console.log("stateName", stateName);
          console.log("stateCode", stateCode);
          console.log("postalCode", postalCode);
          console.log("city-->sZ", city);

          if (stateName && stateCode) {
            setSelectedState({ name: stateName, state_code: stateCode });
            setSelectedStateCode({ name: stateName, state_code: stateCode });
            // AllCitys(stateName);
          }

          if (city) {
            console.log("city---->", city);
            setCity(city); // Assuming you still need city
            setPostalCode("");
          }

          if (postalCode) {
            setPostalCode(postalCode); // Assuming you still need postal code
          }

          setAddress(cleanedAddress); // Set full address
          // setAddress(place.formatted_address); // Set full address
        }
      });
    }

    // Initialize Autocomplete for the second input
    if (inputRef2.current) {
      const autocomplete2 = new window.google.maps.places.Autocomplete(
        inputRef2.current,
        {
          types: ["geocode"], // Restrict to address-type predictions
          componentRestrictions: { country: "us" },
        }
      );

      autocomplete2.addListener("place_changed", () => {
        const place = autocomplete2.getPlace();
        console.log("Place details (input 2):", place);

        if (place) {
          const { stateName, stateCode, city, postalCode } =
            parseAddressComponents(place);

          const cleanedAddress = cleanAddress(place.formatted_address, [
            city,
            stateName,
            postalCode,
          ]);

          if (stateName && stateCode) {
            setSelectedState2({ name: stateName, state_code: stateCode });
            setSelectedStateCode2({ name: stateName, state_code: stateCode });
            // AllCitys(stateName);
          }

          if (city) {
            setCity2(city); // Assuming you need city for second input
            setPostalCode2("");
          }

          if (postalCode) {
            setPostalCode2(postalCode); // Assuming you need postal code for second input
          }

          // setAddress2(place.formatted_address); // Set full address for second input
          setAddress2(cleanedAddress);
        }
      });
    }

    setIsLoading(false);
  };

  // use Effects for checking gmap

  useEffect(() => {
    if (!getsection2 && inputRef1.current) {
      initializeAutocomplete();
    }
  }, [getsection2, inputRef1]);
  useEffect(() => {
    if (getsection2 && inputRef2.current) {
      initializeAutocomplete();
    }
  }, [getsection2, inputRef2]);
  useEffect(() => {
    if (!sameAsShipping && inputRef2.current) {
      initializeAutocomplete();
    }
  }, [sameAsShipping, inputRef2]);
  useEffect(() => {
    if (!isloading && inputRef2.current) {
      initializeAutocomplete();
    }
  }, [isloading, inputRef2]);
  useEffect(() => {
    if (!isloading && inputRef1.current) {
      initializeAutocomplete();
    }
  }, [isloading, inputRef1]);

  useEffect(() => {
    console.log("Updated state code:", getselectedStateCode);
  }, [getselectedStateCode]);

  useEffect(() => {
    const loadScript = (url, callback) => {
      const existingScript = document.querySelector(`script[src="${url}"]`);

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onload = callback;
        script.onerror = () => {
          console.error("Google Maps script failed to load.");
          setIsLoading(false);
        };
        document.body.appendChild(script);
      } else {
        callback();
      }
    };

    // Load Google Maps API script
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDqypMXRxstlMdPZ-LUGWb4w36I0ENsu9U&libraries=places`,
      initializeAutocomplete
    );
  }, []);
  // google map end

  const handleStateChange = async (value) => {
    // setIsLoading(true);
    var params = {
      state_name: value,
    };
    await axios
      .post(cheke_state, params)
      .then((res) => {
        // setIsLoading(false);
        const data = res.data;
        if (data.status === 0) {
          setSelectedState({
            name: "",
            state_code: "",
          });
          setSelectedStateCode({
            name: "",
            state_code: "",
          });
          // setAllCitys([]);
          setCity("");
          notifyError(data.message);
        }
        // else {
        //   AllCitys(value);
        // }
      })
      .catch((err) => {
        console.log("err", err);
        // setIsLoading(false);
      });
  };

  const handleStateChange2 = async (value) => {
    // setIsLoading(true);
    var params = {
      state_name: value,
    };
    await axios
      .post(cheke_state, params)
      .then((res) => {
        // setIsLoading(false);
        const data = res.data;
        if (data.status === 0) {
          setSelectedState2({
            name: "",
            state_code: "",
          });
          setSelectedStateCode2({
            name: "",
            state_code: "",
          });
          // setAllCitys([]);
          setCity2("");
          notifyError(data.message);
        }
        // else {
        //   AllCitys(value);
        // }
      })
      .catch((err) => {
        console.log("err", err);
        // setIsLoading(false);
      });
  };

  const AllCitys = async (state) => {
    setIsCityLoading(true);
    await axios
      .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country: "United States",
        state: state,
      })
      .then((res) => {
        setIsCityLoading(false);
        const data = res.data.data;
        console.log("citys", res.data.data);
        setAllCitys(data);
      })
      .catch((err) => {
        console.log("err", err);
        setIsCityLoading(false);
      });
  };

  // page 1 validation

  const Page1Validate = () => {
    if (email === "" || email === null) {
      notifyError("Please enter Email Address.");
      return;
    } else if (!validateEmail(email)) {
      notifyError("Please enter a valid Email Address.");
      return;
    } else if (firstName === "" || firstName === null) {
      notifyError("Please enter First Name.");
      return;
    } else if (lastName === "" || lastName === null) {
      notifyError("Please enter Last Name.");
      return;
    } else if (address === "" || address === null) {
      notifyError("Please enter Address.");
      return;
      // } else if (address.length > 34) {
      //   notifyError("Address cannot exceed 34 characters.");
      //   return;
    } else if (
      getselectedStateCode.name === "" ||
      getselectedStateCode.name === null
    ) {
      notifyError("Please select State.");
      return;
    } else if (city === "" || city === null) {
      notifyError("Please select City.");
      return;
      // } else if (getallCitys.includes(city) == false) {
      //   notifyError("Please select City.");
      //   return;
    } else if (postalCode === "" || postalCode === null) {
      notifyError("Please enter Postal Code.");
      return;
    } else if (phoneNumber === "" || phoneNumber === null) {
      notifyError("Please enter Phone Number.");
      return;
    } else if (phoneNumber.length < 10) {
      notifyError("Please enter valid Phone Number.");
      return;
    } else {
      setSection2(true);
    }
  };

  // page 2 validations

  const Page2Validate = () => {
    if (cardNumber === "" || cardNumber === null) {
      notifyError("Please enter Card Number.");
      return;
    } else if (cardNumber.length < 16) {
      notifyError("Please enter valid Card Number.");
      return;
    } else if (cardholdername === "" || cardholdername === null) {
      notifyError("Please enter Name on Card.");
      return;
    } else if (expiryDate === "" || expiryDate === null) {
      notifyError("Please enter Expiry Date.");
      return;
    } else {
      // Split the expiry date into month and year
      const [month, year] = expiryDate.split("/");

      // Check if the month and year are valid
      if (!month || !year || month.length !== 2 || year.length !== 2) {
        notifyError(
          "Invalid format. Please enter the expiry date in MM/YY format."
        );
        return;
      }

      // Check if the month is valid (between 01 and 12)
      if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
        notifyError("Invalid month. Please enter a valid month (01-12).");
        return;
      }

      // Check if the year is valid (current year or future year)
      const currentYear = new Date().getFullYear() % 100; // Get last 2 digits of current year
      if (parseInt(year, 10) < currentYear) {
        notifyError("Invalid year. Please enter a valid future year.");
        return;
      }
    }
    if (cvv === "" || cvv === null) {
      notifyError("Please enter Security code.");
      return;
    } else if (cvv.length < 3) {
      notifyError("Please enter valid Security code.");
      return;
    } else if (!sameAsShipping && (firstName2 === "" || firstName2 === null)) {
      notifyError("Please enter First Name.");
      return;
    } else if (!sameAsShipping && (lastName2 === "" || lastName2 === null)) {
      notifyError("Please enter Last Name.");
      return;
    } else if (!sameAsShipping && (address2 === "" || address2 === null)) {
      notifyError("Please enter Address.");
      return;
      // } else if (!sameAsShipping && address2.length > 34) {
      //   notifyError("Address cannot exceed 34 characters.");
      //   return;
    } else if (
      !sameAsShipping &&
      (getselectedStateCode2.name === "" || getselectedStateCode2.name === null)
    ) {
      notifyError("Please select State.");
      return;
    } else if (!sameAsShipping && (city2 === "" || city2 === null)) {
      notifyError("Please select City.");
      return;
      // } else if (!sameAsShipping && getallCitys.includes(city2) == false) {
      //   notifyError("Please select City.");
      //   return;
    } else if (
      !sameAsShipping &&
      (postalCode2 === "" || postalCode2 === null)
    ) {
      notifyError("Please enter Postal Code.");
      return;
    } else if (
      !sameAsShipping &&
      (phoneNumber2 === "" || phoneNumber2 === null)
    ) {
      notifyError("Please enter Phone Number.");
      return;
    } else if (!sameAsShipping && phoneNumber2.length < 10) {
      notifyError("Please enter valid Phone Number.");
      return;
    } else {
      checkout_api();
    }
  };

  const checkout_api = async () => {
    setIsLoading(true);
    const param = {
      email: email,
      card_type: getcardid,
      card_no: cardNumber,
      ex_month: exMonth,
      ex_year: exYear,
      cvv_no: cvv,
      card_holder_name: cardholdername,
      zip_code: postalCode,
      state_name: getselectedStateCode.state_code,
      first_name: firstName,
      last_name: lastName,
      product_id: id,
      // form_id: product?.form_id,
      address: address,
      city_name: city,
      phone: phoneNumber,
      contact_details_id: contact_details_id,
      promo_codes: appliedDiscount,
      start_url: window.location.href,

      billingFirstName: sameAsShipping ? "" : firstName2,
      billingLastName: sameAsShipping ? "" : lastName2,
      billingAddress: sameAsShipping ? "" : address2,
      billingCity: sameAsShipping ? "" : city2,
      billingState: sameAsShipping ? "" : getselectedStateCode2.state_code,
      billingZip: sameAsShipping ? "" : postalCode2,
      billingCountry: sameAsShipping ? "" : "US",
      billingSameAsShipping: sameAsShipping ? "YES" : "NO",
    };

    // console.log("123", param);
    console.log("state_code", getselectedStateCode.state_code);
    console.log("param", param);

    await axios
      .post(add_user_check_product, param)
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          setIsLoading(false);
          // navigate("/SuccessPage");
          setSkipModel(true);
          setorder_id(Response.data.order_id);
        } else if (Response.data.error_details) {
          notifyError(Response.data.error_details.message);
        } else {
          setIsLoading(false);
          notifyError(Response.data.message);
        }
        console.log("====================================");
        console.log("club_get_all_partner_with_benifit res =>", Response.data);
        console.log("====================================");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("eeror------>", error);
      });
  };

  const handleCardChange = (event) => {
    const cardValue = parseInt(event.target.value);
    setCardid(cardValue);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Only set the CVV if the length is 3 or less and it's numeric
    if (value.length <= 3 && /^\d*$/.test(value)) {
      setCvv(value);
    }
  };

  const formatCardNumber = (cardNumber) => {
    // Add spaces every 4 characters
    return cardNumber.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
  };

  const [validEmail, setValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const input = e.target.value;
    setEmail(input);

    // Basic email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(input);

    setValidEmail(isValid);
  };

  const productChange = (id) => {
    console.log("e.targate.value", id);
    navigate(`/${id}`);
    FetchProduct(id);
  };

  // everflow pixel start

  // // Load Everflow script dynamically
  // const loadEverflowScript = () => {
  //   if (
  //     !document.querySelector(
  //       'script[src="https://www.hzbfe8trk.com/scripts/sdk/everflow.js"]'
  //     )
  //   ) {
  //     const script = document.createElement("script");
  //     script.src = "https://www.hzbfe8trk.com/scripts/sdk/everflow.js";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }
  // };

  // // Call EF.click when the component mounts
  // useEffect(() => {
  //   const currentUrl = window.location.href;

  //   // Parse the URL to extract query parameters
  //   const params = new URLSearchParams(new URL(currentUrl).search);

  //   // Get the 'uniqueId' parameter
  //   const click_id = params.get("click_id");
  //   console.log("click_id", click_id);
  //   loadEverflowScript();
  //   window.EF = window.EF || {}; // Ensure EF is available
  //   window.onload = () => {
  //     if (window.EF && window.EF.click) {
  //       window.EF.click({
  //         offer_id: window.EF.urlParameter("oid"),
  //         affiliate_id: window.EF.urlParameter("affid"),
  //         sub1: window.EF.urlParameter("sub1"),
  //         sub2: window.EF.urlParameter("sub2"),
  //         sub3: window.EF.urlParameter("sub3"),
  //         sub4: window.EF.urlParameter("sub4"),
  //         sub5: window.EF.urlParameter("sub5"),
  //         uid: window.EF.urlParameter("uid"),
  //         source_id: window.EF.urlParameter("source_id"),
  //         transaction_id: window.EF.urlParameter("click_id"),
  //       });
  //     }
  //   };
  // }, []);

  // // Trigger the conversion when checkout is complete
  // useEffect(() => {
  //   if (getskipModel) {
  //     loadEverflowScript(); // Load the script dynamically
  //     const timer = setTimeout(() => {
  //       console.log("test data " + email);
  //       console.log("test data " + getorder_id);
  //       if (window.EF && window.EF.conversion) {
  //         window.EF.conversion({
  //           offer_id: 1,

  //           amount:
  //             discount_percent !== "0.00"
  //               ? (
  //                   getdata.product_price -
  //                   (getdata.product_price * discount_percent) / 100
  //                 ).toFixed(0)
  //               : (getdata.product_price - discount_amount).toFixed(0),
  //           email: email,
  //           order_id: getorder_id,
  //         });
  //       } else {
  //         console.error("Everflow SDK not loaded or EF object not available.");
  //       }
  //     }, 500); // Delay to ensure the script has loaded

  //     return () => clearTimeout(timer); // Cleanup the timer
  //   }
  // }, [getskipModel]); // Run effect when checkout is complete

  // // everflow pixel end

  // // useEffect for close modal after 3 secound
  // useEffect(() => {
  //   if (getskipModel) {
  //     loadEverflowScript(); // Load the script dynamically
  //     const timer = setTimeout(() => {
  //       setSkipModel(false);
  //       window.location.assign(
  //         `https://polomeds.com/onboarding/?order_id=${getorder_id}&uniqueId=${uniqueId}`
  //       );
  //     }, 3000); // Delay to ensure the script has loaded

  //     return () => clearTimeout(timer); // Cleanup the timer
  //   }
  // }, [getskipModel]);

  if (isloading) {
    return (
      <div style={{ height: "97vh" }}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      {/* <Navbar2 color="#fff" logo={images.logo} /> */}
      <div className="payment-container" style={{ position: "relative" }}>
        {/* <div className="payment-content">
          <div className="payment-header_p">
            <p className="paymen_p_tag">Checkout</p>
          </div>
        </div> */}
        <div className="payment-content2 payment-column-reverse">
          {/* onSubmit={handleSubmit} */}
          <div className="checkout-form-wrapp">
            <div className="checkout_logo_wrapp headline_for_mob2">
              <img src={images.logo} className="" alt="" />

              <div className="checkout_head_name_wrapp">
                <p>Shipping</p>
                <FiChevronRight size={20} style={{ display: "block" }} />
                <p>Payment</p>
              </div>
            </div>

            {!getsection2 ? (
              <div className="checkout-form">
                {/* contect info */}
                <h3>Contact</h3>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={handleEmailChange}
                    style={{
                      border: validEmail ? "" : "1px solid red",
                    }}
                  />
                  {!validEmail && (
                    <p style={{ color: "red", marginTop: "2px" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                </div>
                <h3>Shipping Address</h3>
                {/* <div className="form-group"> */}
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option>United States</option>
                  {/* Add more country options here */}
                </select>
                {/* </div> */}
                <div className="form-group-inline">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    ref={inputRef1}
                    type="text"
                    placeholder="Address"
                    value={address}
                    // onChange={(e) => setAddress(e.target.value)}
                    onChange={(e) => {
                      if (e.target.value.length == 0) {
                        setSelectedState({
                          name: "",
                          state_code: "",
                        });
                        setSelectedStateCode({
                          name: "",
                          state_code: "",
                        });
                        setCity("");
                        setPostalCode("");
                      }
                      setAddress(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group-inline">
                  <div className="inline_seond">
                    <select
                      value={JSON.stringify({
                        name: getselectedState.name,
                        state_code: getselectedState.state_code,
                      })}
                      onChange={(e) => {
                        const selectedState = JSON.parse(e.target.value);

                        console.log("getselectedStateCode1", selectedState);

                        setSelectedState({
                          name: selectedState.name,
                          state_code: selectedState.state_code,
                        });
                        setSelectedStateCode({
                          name: selectedState.name,
                          state_code: selectedState.state_code,
                        });
                        // setSelectedStateCode(selectedState.state_code);
                        handleStateChange(selectedState.name); // Optional: If you have additional logic
                        console.log(
                          "getselectedStateCode",
                          selectedState.state_code
                        );
                      }}
                    >
                      <option
                        value={JSON.stringify({ name: "", state_code: "" })}
                      >
                        Select state
                      </option>
                      {getstate.map((state) => (
                        <option
                          key={state.state_code}
                          value={JSON.stringify({
                            name: state.name,
                            state_code: state.state_code,
                          })}
                        >
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="inline_seond">
                    {iscityloading ? (
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            border: "3px solid #e0e0e0",
                            borderTop: "3px solid #f39322",
                            borderRadius: "50%",
                            animation: "spin 1s linear infinite",
                          }}
                        ></div>
                      </div>
                    ) : (
                      // <select
                      //   value={city} // Use city directly
                      //   onChange={(e) => {
                      //     setCity(e.target.value);
                      //     setPostalCode("");
                      //   }} // Update city state on selection
                      //   disabled={iscityloading}
                      // >
                      //   <option value="">Select city</option>
                      //   {getallCitys.map((city, index) => (
                      //     <option key={index} value={city}>
                      //       {city}
                      //     </option>
                      //   ))}
                      // </select>
                      <div className="form-group" style={{ width: "100%" }}>
                        <input
                          type="text"
                          placeholder="City"
                          value={city}
                          // onChange={(e) => setFirstName(e.target.value)}
                          onChange={(e) => {
                            setCity(e.target.value);
                            setPostalCode("");
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Postal Code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </div>
                </div>
                {/* <br /> */}
                {/* phone number start */}
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      if (input.length <= 10) {
                        setPhoneNumber(input);
                      }
                    }}
                  />
                </div>
                {/* phone number end */}
                {/* <p>All transactions are secure and encrypted.</p> */}
                <br />
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <input
                    type="checkbox"
                    id="medicationConsent"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{
                      marginTop: "3px",
                      marginRight: "10px",
                      width: "10px",
                      height: "10px",
                      padding: "5px",
                      cursor: "pointer",
                      appearance: "auto",
                      accentColor: "black",
                    }}
                    // className="details_of_checkbox"
                  />
                  <label
                    htmlFor="medicationConsent"
                    style={{
                      fontSize: "14px",
                      lineHeight: "1.5",
                      fontWeight: "300",
                    }}
                  >
                    <strong>Text me when my medication ships</strong> and for
                    exclusive health and weight loss content. I agree to receive
                    marketing text messages from polomeds at the number provided
                    above. Consent is not a condition of purchase. Message &
                    data rates may apply.
                  </label>
                </div>
                {/* Submit btn start */}
                <br />
                {/* Submit btn end */}
                {/* promo code start*/}
                {/* <div className="payment_card">
              <div className="payment_card_titel">
                <b>
                  <RiDiscountPercentLine />
                  Promo Code
                </b>
              </div>

              <div className="form-group-inline">
                <div
                  className="inline_seond promo_input"
                >
                  <input
                    type="text"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    placeholder="Enter Promo Code"
                  />
                </div>
                <div
                  className="inline_seond"
                  style={{ width: "30%", minWidth: "100px" }}
                >
                  <button className="btn" onClick={handleApplyDiscount}>
                    Apply
                  </button>
                </div>
              </div>
            </div> */}
                {/* Display error message */}

                {/* promo code end */}
                <br />
                {/* <br /> */}
                {/* payment details */}
                {/* <div className="payment_card">
              <div className="payment_card_titel">
                <b>
                  <MdOutlinePayment />
                  Payment Methods
                </b>
                <div className="card_icon_div">
                  <img
                    src={images.visa}
                    className="item_img_card"
                    alt="Headphones"
                  />
                  <img
                    src={images.master}
                    className="item_img_card"
                    alt="Headphones"
                  />
                  <img
                    src={images.jcb}
                    className="item_img_card"
                    alt="Headphones"
                  />
                  <img
                    src={images.american}
                    className="item_img_card"
                    alt="Headphones"
                  />
                </div>
              </div>
              <div className="details-card">
                <div className="form-group">
                  <select
                    className="card-dropdown"
                    onChange={handleCardChange}
                    value={getcardid}
                  >
                    <option value={1}>Visa</option>
                    <option value={2}>MasterCard</option>
                    <option value={3}>Discover</option>
                    <option value={4}>American Express</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Card holder name"
                    className="card-number"
                    value={cardholdername}
                    onChange={(e) => setcardholdername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Card Number"
                    className="card-number"
                    value={formatCardNumber(cardNumber)}
                    onChange={(e) => {
                      const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      if (input.length <= 16) {
                        setCardNumber(input);
                      }
                    }}
                  />
                </div>
                <div className="form-group-inline">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="MM/YY"
                      className="expiry-date"
                      value={expiryDate}
                      onChange={(e) => {
                        let input = e.target.value;
                        // Remove non-numeric characters except "/"
                        input = input.replace(/\D/g, "");

                        // Ensure input is limited to 4 characters (MMYY format)
                        if (input.length > 4) {
                          input = input.slice(0, 4);
                        }

                        // Extract MM and YY
                        const month = input.slice(0, 2);
                        const year = input.slice(2, 4);

                        // Update state for ex_month and ex_year
                        setExMonth(month);
                        setExYear(year);

                        // Format input to display MM/YY in the input field
                        setExpiryDate(
                          month + (year.length > 0 ? "/" + year : "")
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="CVV"
                      className="cvv"
                      value={cvv}
                      onChange={handleChange}
                      maxLength={3}
                    />
                  </div>
                </div>
                <div className="note_titel_">
                
                </div>
                <center>
                  <button className="btn-checkout" onClick={checkout_api}>
                    Complete Checkout
                  </button>
                </center>
              </div>
            </div> */}
                <button className="btn2" onClick={Page1Validate}>
                  CONTINUE TO PAYMENT
                </button>
              </div>
            ) : (
              <div className="checkout-form">
                {/* contect box start */}
                <div className="sec2_checkout_box_wrapp">
                  <div>
                    <p>Contact</p>
                    <p>{email}</p>
                    <button
                      onClick={() => {
                        setSection2(false);
                      }}
                    >
                      Change
                    </button>
                  </div>
                  <div style={{ borderBottom: "none" }}>
                    <p>Ship to</p>
                    <p>{address}</p>
                    <button
                      onClick={() => {
                        setSection2(false);
                      }}
                    >
                      Change
                    </button>
                  </div>
                  {/* <div style={{borderBottom:"none"}}>
                    <p>Shipping method</p>
                    <p>Standard</p>
                    <button
                      onClick={() => {
                        setSection2(false);
                      }}
                    >
                      Change
                    </button>
                  </div> */}
                </div>
                {/* contect box end */}
                <br />

                {/* payment box start */}
                <p className="pg2_heading">Payment</p>
                <p className="pg2_des">
                  All transactions are secure and encrypted.
                </p>
                <div className="paymentbox_main_wrapp">
                  <div className="paymentbox_header">
                    <p className="pg2_heading">Credit card</p>
                    <div className="card_icon_div">
                      <img
                        src={images.visa}
                        className="item_img_card"
                        alt="Headphones"
                      />
                      <img
                        src={images.master}
                        className="item_img_card"
                        alt="Headphones"
                      />
                      <img
                        src={images.jcb}
                        className="item_img_card"
                        alt="Headphones"
                      />
                      <img
                        src={images.american}
                        className="item_img_card"
                        alt="Headphones"
                      />
                    </div>
                  </div>
                  <div className="paymentbox_contain">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Card number"
                        className="card-number"
                        value={formatCardNumber(cardNumber)}
                        onChange={(e) => {
                          const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                          if (input.length <= 16) {
                            setCardNumber(input);
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Name on card"
                        className="card-number"
                        value={cardholdername}
                        onChange={(e) => setcardholdername(e.target.value)}
                      />
                    </div>
                    <div className="form-group-inline">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Expiration date (MM/YY)"
                          className="expiry-date"
                          value={expiryDate}
                          onChange={(e) => {
                            let input = e.target.value;
                            // Remove non-numeric characters except "/"
                            input = input.replace(/\D/g, "");

                            // Ensure input is limited to 4 characters (MMYY format)
                            if (input.length > 4) {
                              input = input.slice(0, 4);
                            }

                            // Extract MM and YY
                            const month = input.slice(0, 2);
                            const year = input.slice(2, 4);

                            // Update state for ex_month and ex_year
                            setExMonth(month);
                            setExYear(year);

                            // Format input to display MM/YY in the input field
                            setExpiryDate(
                              month + (year.length > 0 ? "/" + year : "")
                            );
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Security code"
                          className="cvv"
                          value={cvv}
                          onChange={handleChange}
                          maxLength={3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* payment box end */}
                <br />
                {/* billing address start */}

                <p className="pg2_heading">Billing address</p>
                <p className="pg2_des">
                  Select the address that matches your card or payment method.
                </p>

                <div className="paymentbox_main_wrapp">
                  <div className="addressbox_header">
                    <input
                      type="radio"
                      name="addressOption"
                      value="same"
                      checked={sameAsShipping}
                      onChange={() => setSameAsShipping(true)}
                      className="address_box_radio"
                    />
                    <p className="pg2_heading">Same as shipping address</p>
                  </div>
                  <div className="addressbox_header">
                    <input
                      type="radio"
                      name="addressOption"
                      value="different"
                      checked={!sameAsShipping}
                      onChange={() => setSameAsShipping(false)}
                      className="address_box_radio"
                    />
                    <p className="pg2_heading">
                      Use a different billing address
                    </p>
                  </div>
                  {/* Billing Address Form (conditionally rendered) */}
                  {!sameAsShipping && (
                    <div className="paymentbox_contain">
                      <select
                        value={country2}
                        onChange={(e) => setCountry2(e.target.value)}
                      >
                        <option>United States</option>
                        {/* Add more country options here */}
                      </select>
                      <div className="form-group-inline">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="First Name"
                            value={firstName2}
                            onChange={(e) => setFirstName2(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Last Name"
                            value={lastName2}
                            onChange={(e) => setLastName2(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          ref={inputRef2}
                          type="text"
                          placeholder="Address"
                          value={address2}
                          // onChange={(e) => setAddress2(e.target.value)}
                          onChange={(e) => {
                            if (e.target.value.length == 0) {
                              setSelectedState2({
                                name: "",
                                state_code: "",
                              });
                              setSelectedStateCode2({
                                name: "",
                                state_code: "",
                              });
                              setCity2("");
                              setPostalCode2("");
                            }
                            setAddress2(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group-inline">
                        <div className="inline_seond">
                          <select
                            value={JSON.stringify({
                              name: getselectedState2.name,
                              state_code: getselectedState2.state_code,
                            })}
                            onChange={(e) => {
                              console.log("getselectedStateCode-----", e);
                              const selectedState = JSON.parse(e.target.value);
                              // setSelectedState2(selectedState.name);
                              // setSelectedStateCode2(selectedState.state_code);
                              setSelectedState2({
                                name: selectedState.name,
                                state_code: selectedState.state_code,
                              });
                              setSelectedStateCode2({
                                name: selectedState.name,
                                state_code: selectedState.state_code,
                              });
                              handleStateChange2(selectedState.name);
                              console.log(
                                "getselectedStateCode111",
                                getselectedStateCode
                              );
                            }}
                          >
                            <option
                              value={JSON.stringify({
                                name: "",
                                state_code: "",
                              })}
                            >
                              Select state
                            </option>{" "}
                            {getstate &&
                              getstate.map((states, index) => {
                                return (
                                  <option
                                    value={JSON.stringify({
                                      name: states.name,
                                      state_code: states.state_code,
                                    })}
                                  >
                                    {states.name}
                                  </option>
                                );
                              })}
                            {/* Add more state options here */}
                          </select>
                        </div>
                        <div className="inline_seond">
                          {iscityloading ? (
                            <div
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  border: "3px solid #e0e0e0",
                                  borderTop: "3px solid #f39322",
                                  borderRadius: "50%",
                                  animation: "spin 1s linear infinite",
                                }}
                              ></div>
                            </div>
                          ) : (
                            // <select
                            //   value={city2}
                            //   onChange={(e) => {
                            //     setCity2(e.target.value);
                            //     setPostalCode2("");
                            //   }}
                            // >
                            //   <option value={""}>Select city</option>

                            //   {getallCitys.map((city, index) => {
                            //     return <option value={city}>{city}</option>;
                            //   })}
                            //   {/* Add more state options here */}
                            // </select>
                            <div
                              className="form-group"
                              style={{ width: "100%" }}
                            >
                              <input
                                type="text"
                                placeholder="City"
                                value={city2}
                                onChange={(e) => {
                                  setCity2(e.target.value);
                                  setPostalCode2("");
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <div className="inline_seond">
                          <input
                            type="text"
                            placeholder="Postal Code"
                            value={postalCode2}
                            onChange={(e) => setPostalCode2(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Phone number"
                          value={phoneNumber2}
                          onChange={(e) => {
                            const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                            if (input.length <= 10) {
                              setPhoneNumber2(input);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="features">
                  <ul style={{}}>
                    <li style={{ fontSize: "14px" }}>
                      Free online medical consultation.
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      24/7 support via email or phone.
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      100% refund if you are not prescribed.
                    </li>
                  </ul>
                </div>
                <br />
                <button className="btn2" onClick={Page2Validate}>
                  CHECKOUT NOW
                </button>
                <center>
                  <div className="ssl-secured-payment">
                    <FaLock />
                    <p
                      style={{
                        marginLeft: "5px",
                        fontSize: "16px",
                        color: "#212529",
                        fontWeight: "400",
                      }}
                    >
                      SSL SECURED PAYMENT
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        marginLeft: "5px",
                        fontSize: "16px",
                        color: "#212529",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      Your information is protected by 256-bit SSL encryption
                    </p>
                  </div>
                </center>
              </div>
            )}
          </div>
          <div className="center_line"></div>
          <div className="order-summary2">
            <div className="checkout_logo_wrapp headline_for_mob">
              <img src={images.logo} className="" alt="" />

              <div className="checkout_head_name_wrapp">
                <p>Shipping</p>
                <FiChevronRight size={20} style={{ display: "block" }} />
                <p>Payment</p>
              </div>
            </div>
            <div className="product-info-right">
              <div className="product-image">
                <img
                  src={
                    getdata?.product_img && getdata.product_img.length > 0
                      ? getdata.product_img[0]?.img_video
                      : null
                  }
                  alt="Semaglutide"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  alignItems: "center",
                }}
              >
                <div className="product-details">
                  <h4>{getdata?.product_name}</h4>
                  {/* <p style={{ fontSize: "12px" }}>1 Month Supply</p> */}
                </div>
                <div style={{}} className="product-price">
                  <span
                    style={{ marginRight: "10px" }}
                    className="original-price"
                  >
                    {/* {discount_percent == "0.00" ? "$" + `${discount_amount}` : "%" + `${discount_percent}`} */}
                  </span>
                  <span className="discounted-price">
                    ${getdata.product_price}
                  </span>
                </div>
              </div>
            </div>
            {/* Discount Code */}
            <div className="discount-code-section">
              <input
                style={{ width: "75%" }}
                type="text"
                placeholder="Discount Code"
                className="input-field"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
              />
              <button
                style={{ width: "25%" }}
                onClick={handleApplyDiscount}
                className="apply-button"
              >
                <p style={{ color: "white" }}>Apply</p>
              </button>
            </div>
            {/* Display error message */}
            {error && (
              <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
            )}
            {applyLoading && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "3px solid #e0e0e0",
                    borderTop: "3px solid #f39322",
                    borderRadius: "50%",
                    animation: "spin 1s linear infinite",
                  }}
                ></div>
              </div>
            )}
            {/* Display discount badge on success */}
            {appliedDiscount && (
              <div
                style={{
                  marginTop: "10px",
                  backgroundColor: "#e0f7fa",
                  padding: "5px 10px",
                  display: "inline-block",
                  borderRadius: "5px",
                  width: "fit-content",
                  display: "flex",
                }}
              >
                <span style={{ color: "#00796b" }}>
                  ✅ {appliedDiscount} Applied
                </span>
                <button
                  onClick={handleRemoveDiscount}
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#ff7043",
                    color: "white",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  className="remove_coupen_btn"
                >
                  <IoCloseOutline />
                </button>
              </div>
            )}

            {/* Order Summary */}
            <div
              style={{ marginTop: "10px", backgroundColor: "#F7F7F8" }}
              className="order-summary2"
            >
              <p
                style={{
                  fontSize: "20px",
                  marginBottom: "27px",
                  fontWeight: "500",
                  marginTop: "7px",
                }}
                className="order-summary-title"
              >
                Order Summary
              </p>
              <hr className="order_summery_hr" />

              <div className="item">
                <img
                  src={
                    getdata?.product_img && getdata.product_img.length > 0
                      ? getdata.product_img[0]?.img_video
                      : null
                  }
                  className="order_summary_img"
                  alt="Semaglutide"
                />
                <div className="div_card">
                  <p>{getdata?.product_name}</p>
                  <div style={{}} className="product-price">
                    {/* <span
                      style={{ marginRight: "10px" }}
                      className="original-price"
                    >
                      ${getdata?.product_price}
                    </span> */}
                    {/* <span className="discounted-price">${getdata.product_price}</span> */}
                    <span className="discounted-price">
                      {
                        discount_percent !== "0.00"
                          ? "$" +
                            (
                              getdata.product_price -
                              (getdata.product_price * discount_percent) / 100
                            ).toFixed(0) // Calculate discounted price
                          : "$" +
                            (getdata.product_price - discount_amount).toFixed(0) // Subtract fixed discount amount
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <img
                  src={images.stethoscope}
                  className="order_summary_img"
                  alt=""
                />
                <div className="div_card">
                  <p>Doctor Support and Monitoring</p>
                  <div style={{}} className="product-price">
                    <span
                      style={{ marginRight: "10px" }}
                      className="original-price"
                    >
                      $99
                    </span>
                    <span className="discounted-price">free</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <img
                  src={images.metabolism}
                  className="order_summary_img"
                  alt=""
                />
                <div className="div_card">
                  <p>Metabolic testing and blood work included</p>
                  <div style={{}} className="product-price">
                    <span
                      style={{ marginRight: "10px" }}
                      className="original-price"
                    >
                      $75
                    </span>
                    <span className="discounted-price">free</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <img
                  src={images.delivery_truck}
                  className="order_summary_img"
                  alt="Semaglutide"
                />
                <div className="div_card">
                  <p>Overnight Shipping </p>
                  <div style={{}} className="product-price">
                    <span
                      style={{ marginRight: "10px" }}
                      className="original-price"
                    >
                      $40
                    </span>
                    <span className="discounted-price">free</span>
                  </div>
                </div>
              </div>
              {appliedDiscount && (
                <div className="savings-banner">
                  <MdOutlineCelebration size={25} />
                  <span>
                    You saved{" "}
                    {discount_percent == "0.00"
                      ? "$" + `${discount_amount}`
                      : "%" + `${discount_percent}`}{" "}
                    on your order
                  </span>
                </div>
              )}
              <div
                className="your-total-div"
                style={{
                  borderRadius: "5px",
                  border: isExpanded ? "1px solid #ccc" : "",
                }}
              >
                <div
                  style={{
                    borderRadius: "5px",
                    border: isExpanded ? "" : "1px solid #ccc",
                  }}
                  className={`total-section ${isExpanded ? "expanded" : ""}`}
                  onClick={handleExpandClick}
                >
                  <div className="total-details">
                    <CiStar
                      size={25}
                      color="var(--clr-yellow)"
                      className="star_icon"
                    />
                    <span style={{ color: "var(--clr-yellow)" }}>
                      {totalText}{" "}
                      {
                        discount_percent !== "0.00"
                          ? "$" +
                            (
                              getdata.product_price -
                              (getdata.product_price * discount_percent) / 100
                            ).toFixed(0) // Calculate discounted price
                          : "$" +
                            (getdata.product_price - discount_amount).toFixed(0) // Subtract fixed discount amount
                      }
                    </span>
                  </div>
                  <div className="expand-button">
                    <span>{isExpanded ? <FaAngleUp /> : <FaAngleDown />}</span>
                  </div>
                </div>

                {isExpanded && (
                  <div style={{ margin: "10px" }} className="expanded-content">
                    {/* Add your expanded content here */}
                    <div
                      style={{
                        borderTop: "0.5px solid gray",
                        marginTop: "-10px",
                        marginBottom: "20px",
                      }}
                    />
                    {appliedDiscount && (
                      <div className="div_card" style={{ width: "100%" }}>
                        <p style={{ fontWeight: "600" }}>Your Coupon Savings</p>
                        <div style={{}} className="product-price">
                          <span
                            style={{ marginRight: "10px", color: "green" }}
                            className="originsal-price"
                          >
                            {discount_percent == "0.00"
                              ? "$" + `${discount_amount}`
                              : "%" + `${discount_percent}`}
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ color: "black", fontSize: "15px" }}>
                        Subtotal
                      </p>
                      <div style={{}} className="product-price">
                        <span
                          style={{
                            marginRight: "10px",
                            color: "black",
                            fontSize: "15px",
                          }}
                          className="originsal-price"
                        >
                          {/* ${getdata?.product_price} */}
                          {
                            discount_percent !== "0.00"
                              ? "$" +
                                (
                                  getdata.product_price -
                                  (getdata.product_price * discount_percent) /
                                    100
                                ).toFixed(0) // Calculate discounted price
                              : "$" +
                                (
                                  getdata.product_price - discount_amount
                                ).toFixed(0) // Subtract fixed discount amount
                          }
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ color: "black", fontSize: "15px" }}>
                        Estimated Tax
                      </p>
                      <div style={{}} className="product-price">
                        <span
                          style={{
                            marginRight: "10px",
                            color: "black",
                            fontSize: "15px",
                          }}
                          className="originsal-price"
                        >
                          ---
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ color: "black", fontSize: "15px" }}>
                        Shipping
                      </p>
                      <div style={{}} className="product-price">
                        <span
                          style={{
                            marginRight: "10px",
                            color: "black",
                            fontSize: "15px",
                          }}
                          className="originsal-price"
                        >
                          $0
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: "0.5px solid gray",
                        marginTop: "10px",
                        marginBottom: "10px",
                        // width: "90%",
                      }}
                    />
                    <div
                      className="div_card"
                      style={{ width: "100%", alignItems: "center" }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "15px" }}>
                        Total
                      </p>
                      <div style={{}} className="product-price">
                        <span
                          style={{
                            marginRight: "10px",
                            color: "black",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                          className="originsal-price"
                        >
                          {/* ${getdata?.product_price} */}
                          {
                            discount_percent !== "0.00"
                              ? "$" +
                                (
                                  getdata.product_price -
                                  (getdata.product_price * discount_percent) /
                                    100
                                ).toFixed(0) // Calculate discounted price
                              : "$" +
                                (
                                  getdata.product_price - discount_amount
                                ).toFixed(0) // Subtract fixed discount amount
                          }
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        borderRadius: "5px",
                        border: "1px solid green",
                        padding: "20px",
                      }}
                    >
                      <p style={{ fontSize: "10px", lineHeight: "22px" }}>
                        By clicking the “Checkout Now” button above, your card
                        will be billed the discounted price of{" "}
                        {
                          discount_percent !== "0.00"
                            ? "$" +
                              (
                                getdata.product_price -
                                (getdata.product_price * discount_percent) / 100
                              ).toFixed(0) // Calculate discounted price
                            : "$" +
                              (getdata.product_price - discount_amount).toFixed(
                                0
                              ) // Subtract fixed discount amount
                        }{" "}
                        today to reserve your 1 month supply of Semaglutide.
                        After you complete the order by clicking the secure
                        "Checkout Now" button above, your information will be
                        securely saved. You will then be asked to complete a
                        short medical history intake and verify your identity. A
                        polomeds medical professional will review your
                        information, and, if appropriate, prescribe your
                        medication. If you are not eligible for Semaglutide for
                        ANY reason, you will receive a full refund of your
                        purchase price. You have selected the monthly package,
                        which includes a 1 month starter kit. The starter kit
                        includes Semaglutide and detailed instructions on how to
                        properly administer the medication. Each month, you will
                        need to update your prescribing doctor on your progress
                        and they will have the ability to adjust your dosage as
                        needed. Your new billing will continue at{" "}
                        {
                          discount_percent !== "0.00"
                            ? "$" +
                              (
                                getdata.product_price -
                                (getdata.product_price * discount_percent) / 100
                              ).toFixed(0) // Calculate discounted price
                            : "$" +
                              (getdata.product_price - discount_amount).toFixed(
                                0
                              ) // Subtract fixed discount amount
                        }{" "}
                        per month after the first 21 days. It will include
                        Semaglutide, unlimited doctor support, and prescription
                        covered. If at anytime you decide not to continue,
                        simply contect us info@polomeds.com. You will not be
                        billed further. While you are a member, you will have
                        access to chat with your doctor or customer support for
                        any needed assistance.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {getskipModel && (
          <div className="skip_modal_main_wrapp">
            <div className="skip_white_part_wrapp">
              <p className="f-16 fw-500 mb-2">
                Your order is placed successfully.
              </p>
              <div>
                <button
                  className="btn"
                  style={{ width: "100px" }}
                  onClick={() => {
                    // SkipNextMonth();
                    setSkipModel(false);
                    window.location.assign(
                      `http://member.polomeds.com/`
                    );
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Checkout2;
