// ---------------- logos ------------------
import logo from "../assets/logos/black_logo.png";
import Forbes_logo from "../assets/logos/Forbes_logo.png";
import NBC_logo from "../assets/logos/NBC_logo.png";
import fox_logo from "../assets/logos/fox_logo.png";
import wsj_logo from "../assets/logos/wsj_logo.png";
import yahoo_logo from "../assets/logos/yahoo_logo.png";
import The_New_York_Times_logo from "../assets/logos/The_New_York_Times_logo.png";
import male from "../assets/logos/male.png";
import female from "../assets/logos/female.png";

import american from "../assets/logos/american.svg";
import master from "../assets/logos/master.svg";
import jcb from "../assets/logos/jcb.svg";
import visa from "../assets/logos/visa.svg";
import treat_med from "../assets/logos/med.png";
import treat_lock from "../assets/logos/lock.png";
import treat_doctor from "../assets/logos/doctor.png";
import treat_hand from "../assets/logos/hand.png";
import treat_weight from "../assets/logos/weight.png";
import levelup_med_img from "../assets/logos/levelup_med_img.png";

// ---------------- images ------------------
import section_images_1 from "../assets/images/section_images_1.jpg";
import medicalTeam1 from "../assets/images/medical_team_1.jpg";
import medicalTeam2 from "../assets/images/medical_team_2.jpg";
import medicalTeam3 from "../assets/images/medical_team_3.jpg";
import gpl_girl_img from "../assets/images/gpl_girl_img.jpg";
import Flow1_img from "../assets/images/flow_img_1.png";
import graph from "../assets/images/graph.png";
import goal_graph from "../assets/images/goal_graph.png";
import metabolic_graph from "../assets/images/metabolic_graph.png";
import plan_graph from "../assets/images/plan_graph.png";
import treatment_doctor_img from "../assets/images/treatment_doctor_img.png";
import prescription_bg from "../assets/images/prescription_bg.jpg";

import product_icon from "../assets/logos/product_icon.png";
import delivery_truck from "../assets/logos/delivery-truck.png";
import stethoscope from "../assets/logos/stethoscope-medical-tool.png";
import metabolism from "../assets/logos/metabolism.png";


export default {
  // ---------------- logos ------------------
  logo,
  Forbes_logo,
  NBC_logo,
  fox_logo,
  wsj_logo,
  yahoo_logo,
  The_New_York_Times_logo,

  visa,
  master,
  jcb,
  american,

  treat_doctor,
  treat_hand,
  treat_lock,
  treat_med,
  treat_weight,
  levelup_med_img,

  // ---------------- images ------------------
  section_images_1,
  medicalTeam1,
  medicalTeam2,
  medicalTeam3,
  gpl_girl_img,
  Flow1_img,
  graph,
  goal_graph,
  metabolic_graph,
  plan_graph,
  male,
  female,
  treatment_doctor_img,
  prescription_bg,

  product_icon,
  delivery_truck,
  stethoscope,
  metabolism,
};
